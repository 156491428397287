import {Chain, sepolia, taiko} from "viem/chains";
import {environment} from "../../../environments/environment";
import {createWeb3Modal, defaultWagmiConfig} from "@web3modal/wagmi";
import { http } from '@wagmi/core'

export function getNetworkId(): Chain {
    return (!environment.production) ? sepolia : taiko;
}

export const projectId = 'da300f916625fdde13bbba58e0210291'
const chain = getNetworkId();
const chains = [chain] as const

export const config = defaultWagmiConfig({
    chains,
    projectId,
    syncConnectedChain: true,
    metadata: {
        name: 'ClickerTaiko',
        description: 'Web3Modal Example',
        url: 'https://web3modal.com', // origin must match your domain & subdomain
        icons: ['https://avatars.githubusercontent.com/u/99078433?s=200']
    },
    transports: {
         [chain.id]: http(),
     }
})

export const web3Modal = createWeb3Modal({
    wagmiConfig: config,
    projectId,
    allowUnsupportedChain: true,
    enableAnalytics: false, // Optional - defaults to your Cloud configuration
    enableOnramp: false // Optional - false as default
})
