<div class="container-toolbar flex flex-row items-center">
    <img src="/assets/images/lettering-brigade.png" width="174" height="48" draggable="false" style="user-select: none;"/>
    <span style="flex: 1 1 0"></span>
    <div class="flex flex-row items-center hide-on-mobile">
        <app-wallet-button *ngIf="!(address$ | async); else profile"></app-wallet-button>
        <ng-template #profile>
            <div class="full-height flex flex-row items-center" style="gap: 14px">
                <div class="container-points shards flex flex-row items-center">
                    <span class="text">{{homeData?.shards ?? '--'}}</span>
                </div>
                <div class="container-points flex flex-row items-center">
                    <span class="text">{{homeData?.points ?? '--'}}</span>
                </div>
                <img class="pointer" src="assets/images/profile.png" width="88" height="88"
                     (mouseenter)="showMenu = true"
                     (click)="toggleMenu()"/>
            </div>
            <div class="menu" *ngIf="showMenu" (mouseleave)="closeMenu()">
                <div class="row">
                    <img src="assets/images/ic-taiko-icon.svg"/>
                    <span class="ellipsis-nowrap">{{address$ | async}}</span>
                </div>
                <div class="row">
                    <img src="assets/images/ic-trophy.png"/>
                    <span class="view-right-anim">{{homeData?.tier ?? '--'}}</span>
                </div>
                <div class="row">
                    <img src="assets/images/ic-world.png"/>
                    <span class="leaderboard items-center" *ngIf="leaderboardData; else emptyleaderboard">
                        <span>{{leaderboardData.position}}</span>
                        <span class="suffix">{{'th'}}</span>
                        <span style="margin-left: 2px; opacity: 0.7">{{'/ ' + leaderboardData.tot}}</span>
                        <span style="margin-left: 6px;">{{'(Top ' + leaderboardData.top + '%)'}}</span>
                    </span>
                    <ng-template #emptyleaderboard>
                        <span>--</span>
                    </ng-template>
                </div>
                <div class="row parent-right-anim pointer" (click)="logout()">
                    <img src="assets/images/ic-logout.png"/>
                    <span class="view-right-anim" style="color: #FFAEB7">Logout</span>
                </div>
            </div>
        </ng-template>
    </div>
    <img [src]="showMobileMenu ? '/assets/images/button-menu-close.png' : '/assets/images/button-menu.png'" width="52"
         height="52" style="margin-left: 12px"
         class="object-contain show-on-mobile pointer scale-on-hover"
         draggable="false"
         (click)="showMobileMenu = !showMobileMenu; closeMenu()"/>

    <div class="container-menu-mobile show-on-mobile" *ngIf="showMobileMenu">
        <div class="menu-mobile" *ngIf="showMobileMenu">
            <div class="flex flex-row justify-center" style="margin-top: 12px">
                <app-wallet-button *ngIf="!(address$ | async); else profileMobile"></app-wallet-button>
                <ng-template #profileMobile>
                    <div class="flex flex-row full-width items-center" style="gap: 8px;">
                        <img class="pointer"
                             src="assets/images/profile.png"
                             width="64" height="64"/>
                        <div class="field-container flex flex-row items-center">
                            <img src="assets/images/ic-gem-small.png" width="24" height="24" />
                            <span>{{homeData?.points ?? '--'}}</span>
                        </div>

                        <div class="field-container flex flex-row items-center">
                            <img src="assets/games/SHARDS.png" width="24" height="24" />
                            <span>{{homeData?.shards ?? '--'}}</span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="flex flex-col" style="height: 178px" *ngIf="(address$ | async)">
                <div class="row parent-right-anim">
                    <img src="assets/images/ic-taiko-icon.svg"/>
                    <span class="ellipsis-nowrap view-right-anim">{{(address$ | async) || '--'}}</span>
                </div>
                <div class="row parent-right-anim">
                    <img src="assets/images/ic-trophy.png"/>
                    <span class="view-right-anim">{{homeData?.tier ?? '--'}}</span>
                </div>
                <div class="row parent-right-anim">
                    <img src="assets/images/ic-world.png"/>
                    <span class="leaderboard items-center" *ngIf="leaderboardData; else emptyleaderboard">
                        <span>{{leaderboardData.position}}</span>
                        <span class="suffix">{{'th'}}</span>
                        <span style="margin-left: 2px; opacity: 0.7">{{'/ ' + leaderboardData.tot}}</span>
                        <span style="margin-left: 6px;">{{'(Top ' + leaderboardData.top + '%)'}}</span>
                    </span>
                    <ng-template #emptyleaderboard>
                        <span>--</span>
                    </ng-template>
                </div>
                <div class="row parent-right-anim pointer" (click)="logout(); showMobileMenu = false">
                    <img src="assets/images/ic-logout.png"/>
                    <span class="view-right-anim" style="color: #FFAEB7">Logout</span>
                </div>
            </div>
        </div>
    </div>
</div>
